import React from "react"

// MUI
import {Container} from "@mui/material";
// CSS
import "./brandsCarouselStyles.css"


export default function BrandsCarousel() {


    const assets_base_url: string = "https://unitag-public-assets.s3.eu-west-1.amazonaws.com/website"

    const brands = [
        "altice", "buzzman", "CEPOVETT", "CHANEL", "cortess", "croixrouge", "Dassauld", "DECATHLON",
        "Deltacore", "Doopera", "Loereal", "Migros", "MKG", "Partoo", "Schneider", "SGS", "SNP", "TALIS",
        "Timberland", "Unilever", "UNIPAY", "vaillant", "vin bordeaux", "WB",

        "altice", "buzzman", "CEPOVETT", "CHANEL", "cortess", "croixrouge", "Dassauld", "DECATHLON",
        "Deltacore", "Doopera", "Loereal", "Migros", "MKG", "Partoo", "Schneider", "SGS", "SNP", "TALIS",
        "Timberland", "Unilever", "UNIPAY", "vaillant", "vin bordeaux", "WB",
    ]

    return (
        <>
            <Container maxWidth={false} className={"carouselBanner"}>
                <div className={"carouselWrapper"}>
                    <div className={"carouselRow"} key={brands.length*2}>
                        {brands.map((brand: any, index: number) => (
                            <div className={"carouselItem"} key={index}>
                                <img
                                    src={assets_base_url+`/landing/brands/${brand}.png`}
                                    height={50}
                                    width={100}
                                    alt={"Brand logo"}
                                    draggable={false}
                                    style={{
                                        maxHeight: 120,
                                        height: "inherit",
                                        width: "inherit"
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                </div>
            </Container>
        </>
    )
}
